@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
    font-family: 'BookkMyungjo-Bd';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2302@1.0/BookkMyungjo-Bd.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'SF_HailSnow';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2106@1.1/SF_HailSnow.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OAGothic-ExtraBold';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2302@1.0/OAGothic-ExtraBold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
}

.dongarifont {
    font-family: 'BookkMyungjo-Bd';
}

.snowFont {
    font-family: 'SF_HailSnow';
}

.OAtitle {
    font-family: 'OAGothic-ExtraBold';
    color: white;
    -webkit-text-stroke: 1px #155739;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
}

.oatitleshadownone {
    text-shadow: none !important;
}

.OA {
    font-family: 'OAGothic-ExtraBold';
}

body {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'SF_HailSnow';
    min-height: 100vh;
    margin: 0;
}

@media (min-width: 375px) {
    .App,
    .fixedsubtitle,
    .Nav,
    .Healingimg img {
        width: 375px;
    }
}

@media (max-width:500px) {
    .App,
    .fixedsubtitle,
    .Nav,
    .Healingimg img {
        width: 100vw;
    }
}

#root {
    box-shadow: rgba(100, 100, 111, 0.5) 0px 7px 29px 0px;
}

.App {
    min-height: 100vh;
}


/* Home */

.Nav img {
    width: 80px;
}

.titlegreen {
    color: #155739;
}

.menugreen {
    background-color: #155739;
}

.Backcolor {
    background-color: #FBF4E8;
    min-height: 100vh;
    background-image: url('../public/assets/backGrass.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
}

.Main {
    background-image: url('../public/assets/all.png');
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.Main2 {
    background: rgb(148, 198, 138);
    background: linear-gradient(180deg, rgba(148, 198, 138, 1) 32%, rgba(251, 244, 232, 1) 89%);
}

.Vill {
    margin-bottom: -40px;
    cursor: pointer;
}

.vill_character {
    width: 125px;
    top: 20px;
}

.vill_title {
    top: 30px;
    padding: 1px 15px;
    border-radius: 20px;
}

.Vill .sketch {
    background-color: #F9EDAA;
    border: 5px solid #F7E375;
    left: 100px !important;
}

.Vill .dongari {
    background-color: #F9E0E2;
    border: 5px solid #F3C4C9;
}

.Vill .neighbor {
    background-color: #C4ECF5;
    border: 5px solid #9AD5E3;
}

.Vill .joy {
    background-color: #D4BDE7;
    border: 5px solid #BB9DD3;
}

.Vill .game {
    background-color: #F4B7B7;
    border: 5px solid #EB9898;
}

.Vill .healing {
    background-color: #D3F2B3;
    border: 5px solid #B9E08F;
}

.Vill .right_img {
    right: 0;
}

.Vill .right_title {
    right: 70px;
}

.Vill .right_ex {
    right: 120px;
}

.Vill .left_img {
    left: 0;
}

.Vill .left_title {
    left: 70px;
}

.Vill .left_ex {
    left: 115px;
}