.collabo .team-logo > img {
    width: 80px;
    height: auto;
}

.text-gray {
    color: #565656;
}

.font-size10 {
    font-size: 10px;
}
.pbox {
    width: 120px;
    height: 170px;
    background-color: #ffffff;
    border-radius: 20px;
    border: 1px solid #155739;
}

.Pimg {
    height: 70px;
    width: auto;
    margin-bottom: 11px;
}

.pbox {
    padding: 5px;
}

.pbox span {
    display: block;
}

.Pinfo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 3px;
    align-items: center;
}

.Pinfo img {
    height: 18px;
    width: auto;
    margin-right: 5px;
}

.Pinfo span {
    font-size: 5px;
}

.Pname {
    color: #404040;
}

.Pdepart {
    margin: 0;
    font-size: 9px;
    color: #155739;
}

.Pposi {
    font-size: 9px;
    margin: 0;
    color: #155739;
}

.Prole {
    color: #404040;
}
.people{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}