@tailwind base;
@tailwind components;
@tailwind utilities;

/* Subtitle */

.Subtitle div {
    width: 200px;
    height: 50px;
    font-size: 27px;
}

.Subtitle img {
    bottom: -10px;
}

.Subtitle .right {
    right: -25px;
}

.Subtitle .left {
    left: -25px;
}

.Subtitle .timetableimg {
    left: -38px !important;
}

.Subtitle .sketch_color {
    background-color: #F0D7AA;
    color: #ECAA24;
}

.Subtitle .dongari_color {
    background-color: #F9DEE1;
    color: #F4B3BA;
}

.Subtitle .neighbor_color {
    background-color: #BFD8EE;
    color: #5C91C6;
}

.Subtitle .joy_color {
    background-color: #E0C0EC;
    color: #AE87BC;
    font-size: 25px;
}

.Subtitle .game_color {
    background-color: #F4AEAD;
    color: #E94948;
    font-size: 25px;
}

.Subtitle .joy_color .left,
.Subtitle .game_color .left {
    left: -30px;
}

.Subtitle .joy_color .left,
.Subtitle .game_color .right {
    right: -30px;
}


/* SwitchBtn */

.Sketch .sketch_color {
    color: #ECAA24;
    border-color: #ECAA24;
}

.Sketch .sketch_color_off {
    color: #E9CF93;
    border-color: #E9CF93;
}

.Neighbor .neighbor_color {
    color: #5C91C6;
    border-color: #5C91C6;
}

.Neighbor .neighbor_color_off {
    color: #BFD8EE;
    border-color: #BFD8EE;
}

.Joy .joy_color {
    color: #AE87BC;
    border-color: #AE87BC;
}

.Joy .joy_color_off {
    color: #E0C0EC;
    border-color: #E0C0EC;
}

.Vill .sketch_color {
    color: #ECAA24;
}

.Vill .dongari_color {
    color: #F4B3BA;
}

.Vill .neighbor_color {
    color: #5C91C6;
}

.Vill .joy_color {
    color: #AE87BC;
}

.Vill .game_color {
    color: #E94948;
}

.Vill .healing_color {
    color: #92CA86;
}

.Healing {
    background-color: #FEF7E7;
    height: 100vh;
}

.Healingimg {
    background-color: #FEF7E7;
}


/* Dongari */

.boothcontent div {
    text-align: start;
    word-break: keep-all;
}


/* Dongari 검색창 */

.dongariSearch input {
    border: 5px solid #F5B3BA;
    background: none;
}

.dongariSearch .searchIcon {
    top: 22%;
    right: 15%;
}

.college {
    color: #F4B3BA;
    font-size: 23px;
}


/* 학과 */

.Department .Dtitle {
    background-color: #FFDADE;
    border: 1px solid #F0949E;
    left: 25px;
    top: -10px;
}

.Department .Dexplain {
    border: 2px solid #F0949E;
}

.Department .Dimg {
    min-width: 30px;
    margin-left: 10px;
}

.Department .Dimg img {
    width: 30px;
}

.last:last-child {
    min-height: 60vh;
}

.threeParent {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, 1fr));
}

.three:first-child {
    margin-top: 1rem;
    grid-row: span 2 / span 2;
    /* ----------------------------------------------
    * Generated by Animista on 2023-9-13 0:14:3
    * Licensed under FreeBSD License.
    * See http://animista.net/license for more info. 
    * w: http://animista.net, t: @cssanimista
    * ---------------------------------------------- */
    /**
    * ----------------------------------------
    * animation flip-scale-2-hor-top
    * ----------------------------------------
    */
    @-webkit-keyframes flip-scale-2-hor-top {
        0% {
            -webkit-transform: translateY(0) rotateX(0) scale(1);
            transform: translateY(0) rotateX(0) scale(1);
            -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
        }
        50% {
            -webkit-transform: translateY(-50%) rotateX(-90deg) scale(2);
            transform: translateY(-50%) rotateX(-90deg) scale(2);
            -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
        }
        100% {
            -webkit-transform: translateY(-100%) rotateX(-180deg) scale(1);
            transform: translateY(-100%) rotateX(-180deg) scale(1);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
        }
    }
    @keyframes flip-scale-2-hor-top {
        0% {
            -webkit-transform: translateY(0) rotateX(0) scale(1);
            transform: translateY(0) rotateX(0) scale(1);
            -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
        }
        50% {
            -webkit-transform: translateY(-50%) rotateX(-90deg) scale(2);
            transform: translateY(-50%) rotateX(-90deg) scale(2);
            -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
        }
        100% {
            -webkit-transform: translateY(-100%) rotateX(-180deg) scale(1);
            transform: translateY(-100%) rotateX(-180deg) scale(1);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
        }
    }
}


/* Nav */

.nav-bg-scrolled {
    background-color: rgb(251, 244, 232, 0.1);
    backdrop-filter: blur(4px);
}

@layer utilities {
    .textShadow {
        text-shadow: 2px 1px 0 gray;
    }
}

.hamburger-menu {
    height: 40px;
    width: 35px;
    position: relative;
    padding: 1rem;
}

.ham-bar {
    width: 70%;
    height: 4px;
    border-radius: 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform .6s, opacity .8s, top .6s;
}

.bar-top {
    top: 25%;
}

.bar-bottom {
    top: 75%
}

.hamburger-menu.active .bar-top {
    transform: translate(-50%, -50%) rotate(-315deg);
    top: 50%
}

.hamburger-menu.active .bar-mid {
    opacity: 0;
}

.hamburger-menu.active .bar-bottom {
    transform: translate(-50%, -50%) rotate(-225deg);
    top: 50%;
}

.off-screen-menu {
    top: 0;
    left: 0;
    background-color: white;
    height: 100vh;
    width: 100%;
    opacity: 0;
    transition: opacity .6s ease-in-out;
}

.off-screen-menu.open {
    opacity: 1;
}

.slideInDown {
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes slideInDown {
    0% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
        visibility: visible;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes slideInDown {
    0% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
        visibility: visible;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}


/* Element box */

.SketchElement .box {
    background-color: rgb(240, 215, 170, 0.37);
}

.Element {
    margin: 0 auto;
    margin-top: 1.5rem;
    width: 91.666667%;
}

.neighborElement .box {
    background-color: rgb(191, 216, 238, 0.5);
}

.GameElement .box {
    background-color: rgb(244, 174, 173, 0.3);
}

.slide-in {
    animation: change 0.5s ease forwards;
}

@keyframes change {
    from {
        transform: rotateY(90deg);
        visibility: visible;
    }
    to {
        transform: translateX(0%);
    }
}


/* neighbor */

.NeighborsmallTag {
    color: #5C91C6;
    font-size: 14px;
}

.neighborfontcolor {
    color: #5C91C6;
}


/* game */

.gamebtn {
    background-color: white;
    border: 1px solid #ec6362;
    color: #EE4341;
    font-weight: bold;
    width: 100px;
}

.gamelast img {
    margin-right: -10px;
}

.gamelast span {
    font-size: 18px;
}


/* Nav */

.ShowNav {
    background-color: #B4D7F1;
    background-image: url('../../public/assets/navimg.png');
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
}

.ShowNav ul {
    color: white;
}

@media (min-width: 375px) {
    .ShowNav {
        width: 375px;
    }
}

@media (max-width:500px) {
    .ShowNav {
        width: 100vw;
    }
}

.presentBtn button {
    background-color: #ADCE77;
    color: white;
    padding: 5px 25px;
    border-radius: 20px;
}

.locationbtn button {
    font-size: 15px;
    width: 110px;
    border-radius: 20px;
    padding: 8px;
    margin: 0 5px;
    box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.4);
    font-weight: bold;
}

.sketchbtn {
    color: #FFAB00;
    background-color: #FDE6BE;
}

.dongaribtn {
    color: #FC7D8B;
    background-color: #F9DEE1;
}

.neighborbtn {
    color: #247ACF;
    background-color: #BFD8EE;
}


/* modal */

.modalBack {
    background-color: #FBF4E8;
}

.headermodal {
    justify-content: end !important;
}

.modalbody {
    border-radius: 10px;
    padding-bottom: 3rem !important;
}

.modalbody>div>img {
    padding: 10px 0;
}

.modaltitle {
    color: #155739;
}

.modaltitlegame {
    color: #155739;
    font-size: 32px;
    -webkit-text-stroke-width: 0.3px;
    -webkit-text-stroke-color: white;
    text-shadow: 3px 2px 3px rgba(0, 0, 0, 0.3);
}

.basicColor {
    background-color: rgb(251, 244, 232);
}

.dongariContenttitle {
    color: #ec6362;
}

.healingText {
    height: 100vh;
}

.healingText span {
    color: #0D482D;
    -webkit-text-stroke: 0.5px white;
}


/* joy */

.timetable {
    background-color: rgba(202, 143, 224, 0.26);
}

.timetable div:first-child {
    color: #705879;
    font-weight: bold;
}

.dongariLogo1 {
    right: 50px;
    bottom: 40px;
}

.dongariLogo2 {
    left: 70px;
    bottom: 20px;
}

.dongariLogo3 {
    right: 60px;
    bottom: 50px;
}

.dongariLogo4 {
    left: 60px;
    bottom: 30px;
}

.dongariLogo5 {
    right: 30px;
    bottom: 40px;
}

.dongariLogo6 {
    left: 70px;
    bottom: 40px;
}

.dongariLogo7 {
    right: 70px;
    bottom: 30px;
}


/* animation */

.shake-lr {
    animation: shake-lr 1s cubic-bezier(.455, .03, .515, .955) both
}

@keyframes shake-lr {
    0%,
    100% {
        transform: rotate(0deg);
        transform-origin: 50% 50%
    }
    10% {
        transform: rotate(8deg)
    }
    20%,
    40%,
    60% {
        transform: rotate(-10deg)
    }
    30%,
    50%,
    70% {
        transform: rotate(10deg)
    }
    80% {
        transform: rotate(-8deg)
    }
    90% {
        transform: rotate(8deg)
    }
}

.slit-in-vertical {
    animation: slit-in-vertical .45s ease-out both
}

@keyframes slit-in-vertical {
    0% {
        transform: translateZ(-800px) rotateY(90deg);
        opacity: 0
    }
    54% {
        transform: translateZ(-160px) rotateY(87deg);
        opacity: 1
    }
    100% {
        transform: translateZ(0) rotateY(0)
    }
}

.tracking-in-expand-fwd-top {
    animation: tracking-in-expand-fwd-top 1s cubic-bezier(.215, .61, .355, 1.000) both
}


/* ----------------------------------------------
 * Generated by Animista on 2023-9-12 20:31:35
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@keyframes tracking-in-expand-fwd-top {
    0% {
        letter-spacing: -.5em;
        transform: translateZ(-700px) translateY(-500px);
        opacity: 0
    }
    40% {
        opacity: .6
    }
    100% {
        transform: translateZ(0) translateY(0);
        opacity: 1
    }
}

.tracking-in-contract-bck-bottom {
    animation: tracking-in-contract-bck-bottom 1s cubic-bezier(.215, .61, .355, 1.000) both
}


/* ----------------------------------------------
 * Generated by Animista on 2023-9-12 20:31:46
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@keyframes tracking-in-contract-bck-bottom {
    0% {
        letter-spacing: 1em;
        transform: translateZ(400px) translateY(300px);
        opacity: 0
    }
    40% {
        opacity: .6
    }
    100% {
        transform: translateZ(0) translateY(0);
        opacity: 1
    }
}

.bounce-bottom {
    -webkit-animation: bounce-bottom 0.9s both;
    animation: bounce-bottom 0.9s both;
}


/* ----------------------------------------------
 * Generated by Animista on 2023-9-13 17:8:41
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */


/**
 * ----------------------------------------
 * animation bounce-bottom
 * ----------------------------------------
 */

@-webkit-keyframes bounce-bottom {
    0% {
        -webkit-transform: translateY(45px);
        transform: translateY(45px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 1;
    }
    24% {
        opacity: 1;
    }
    40% {
        -webkit-transform: translateY(24px);
        transform: translateY(24px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    65% {
        -webkit-transform: translateY(12px);
        transform: translateY(12px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    82% {
        -webkit-transform: translateY(6px);
        transform: translateY(6px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    93% {
        -webkit-transform: translateY(4px);
        transform: translateY(4px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    25%,
    55%,
    75%,
    87% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
    }
}

@keyframes bounce-bottom {
    0% {
        -webkit-transform: translateY(45px);
        transform: translateY(45px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 1;
    }
    24% {
        opacity: 1;
    }
    40% {
        -webkit-transform: translateY(24px);
        transform: translateY(24px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    65% {
        -webkit-transform: translateY(12px);
        transform: translateY(12px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    82% {
        -webkit-transform: translateY(6px);
        transform: translateY(6px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    93% {
        -webkit-transform: translateY(4px);
        transform: translateY(4px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    25%,
    55%,
    75%,
    87% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
    }
}

.roulette {
    color: #5F5F5F;
}

.leaf {
    width: 12px;
    margin-right: 1px;
}

.joytimetabletext {
    grid-template-columns: auto 1fr;
}

.joytimetabletext p {
    text-align: start;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: visible;
}

.joytimetabletext span {
    margin-left: 2px;
    flex-grow: 1;
}

.pulsate-fwd {
    -webkit-animation: pulsate-fwd 1s ease-in-out infinite both;
    animation: pulsate-fwd 1s ease-in-out infinite both;
}


/* ----------------------------------------------
 * Generated by Animista on 2023-9-13 19:11:54
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */


/**
 * ----------------------------------------
 * animation pulsate-fwd
 * ----------------------------------------
 */

@-webkit-keyframes pulsate-fwd {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    50% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes pulsate-fwd {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    50% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}